/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
 /* jshint latedef: false */
(function($) {
    var bpoint = 1,
        $document, $body, $content;
    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                $body = $("body");
                $document = $("#document");
                $content = $("#content");
                $('body').flowtype({
                   minFont   : 20,
                   maxFont   : 28,
                   fontRatio : 40
                });
                // Forms
                forms();
                //Search Forms
                searchForms();
                // Media 
                slideshows();
                
                $.bigfoot();
                
                $("#site-header").addClass("sticky").stick_in_parent({
                  parent: $("#document"),
                }).on("sticky_kit:stick", function(e) {
                  $("> div", e.target).addClass("stuck");
                })
                .on("sticky_kit:unstick", function(e) {
                  $("> div", e.target).removeClass("stuck");
                  $content.stop().transition({ opacity: 1 });
                });
                
                $("#site-header").hover(
                  function() {
                    if($("#site-header").hasClass('is_stuck')) {
                      $content.stop().transition({ opacity: 0.5 });
                    }
                  },
                  function() {
                    $content.stop().transition({ opacity: 1 });
                  }
                );
                
                // Register Breakpoints
                Breakpoints.on({
                    name: "BREAK2",
                    matched: function() {
                      UTIL.breakEvents(2);
                    },
                    exit: function() {
                      UTIL.breakEvents(1);
                    },
                });
            },
            resize: function() { 
              console.log("common resize");
              $(".slideshow").each(function() {
                  var $t = $(this);
                  $t.height(parseInt($(".seq-in", $t).height()));
              });
            },
            breakpoint: function(point) {
              console.log(point);
              var map = {
                1: function() {
                  $body.removeClass("breakpoint-2");
                },
                2: function() {
                  $body.addClass("breakpoint-2");
                  
                },
              };
              var toggleBreak = map[point];
              if (toggleBreak) {
                toggleBreak();
              }
            },
            complete: function() {
              console.log("common complete");
              $('html').addClass('initiated');
            }
        },
        'splash': {
          init: function() {
            
            var $splash = $("#splash");
            
            function removeSplash() {
              $splash.fadeOut(250, function() {
                $body.removeClass("splash-initiated");
                clearTimeout(timeoutSplash);
              });
            }
              
            console.log(sessionStorage, sessionStorage.getItem('splashed'));
            
            if(sessionStorage && !sessionStorage.getItem('splashed')) {
              $splash.show();
              $body.addClass("splash-initiated");
              sessionStorage.setItem('splashed', true);
              var timeoutSplash = window.setTimeout(removeSplash, 8000);
              $(".continue").click(function(e) {
                e.preventDefault();
                removeSplash();
              });
            }
          }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';
            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');
            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                console.log(classnm);
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'resize');
            });
            // Fire common finalize JS
            UTIL.fire('common', 'resize');
            UTIL.fire('common', 'complete');
        },
        resizeEvents: function() {
            // Fire common init JS
            UTIL.fire('common', 'resize');
            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm, 'resize');
            });
        },
        breakEvents: function(point) {
            // Fire common init JS
            UTIL.fire('common', 'breakpoint', point);
            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm, 'breakpoint', point);
            });
        }
    };
    // Load Events
    $(document).ready(UTIL.loadEvents);
    $(window).resize(UTIL.resizeEvents);
})(jQuery); // Fully reference jQuery after this point.